<template>
  <div class="affiliation-display-container">
    <span class="affiliation-title">Your Affiliation</span>
    <router-link
      :to="{
        name: 'affiliationForm',
        params: {
          tourAgency: tourAgency,
          information: {
            bankInformation: bankInformation,
            id: $route.params.id,
          },
        },
      }"
      class="edit-button"
      :style="{
        borderColor: $store.getters.color.color1,
        backgroundColor: $store.getters.color.color1,
        color: $store.getters.color.fontColor,
      }"
      >Edit</router-link
    ><br />
    <br />
    <span class="link-span">Your Link:</span>
    <span class="link-span">{{ link }}</span>
    <button type="button" v-clipboard:copy="link" class="copy-button">
      copy
    </button>
    <br /><br />
    <span> {{ "Used: " + this.travel.length + " times" }}</span>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "affiliationDisplay",
  data() {
    return {
      link: "",
      travel: "",
      bankInformation: {},
      tourAgency: this.$route.params.tourAgency,
    };
  },
  methods: {
    async getInformation() {
      try {
        let res = await HandleApi.getAffiliationInformation(
          this.$route.params.id
        );
        this.link =
          window.location.href.substring(
            0,
            window.location.href.indexOf("/affiliation")
          ) +
          "/affiliation/submit/" +
          this.$route.params.id;
        this.bankInformation = res.bankInformation;
        this.travel = res.travel;
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
  },
  created() {
    this.getInformation();
  },
};
</script>

<style scoped>
.affiliation-title {
  font-size: 20px;
  font-weight: bold;
}

.affiliation-display-container {
  text-align: center;
}

.edit-button {
  box-shadow: 2px 2px 2px lightgrey;
  font-size: 17px;
  border: 2px solid grey;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 7px;
  margin: auto 0;
  display: inline-block;
  padding: 5px;
  text-decoration: none;
  margin-left: 10px;
}

.edit-button:hover {
  text-decoration: underline;
}

.link-span {
  margin-right: 5px;
}

.copy-button {
  box-shadow: 2px 2px 2px lightgrey;
  color: white;
  border: 2px solid #008467;
  font-size: 17px;
  background-color: #008467;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 7px;
  margin: auto 0;
  display: inline-block;
  padding: 5px;
  text-decoration: none;
  margin-left: 10px;
}

.copy-button:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
