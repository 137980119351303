var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"affiliation-display-container"},[_c('span',{staticClass:"affiliation-title"},[_vm._v("Your Affiliation")]),_c('router-link',{staticClass:"edit-button",style:({
      borderColor: _vm.$store.getters.color.color1,
      backgroundColor: _vm.$store.getters.color.color1,
      color: _vm.$store.getters.color.fontColor,
    }),attrs:{"to":{
      name: 'affiliationForm',
      params: {
        tourAgency: _vm.tourAgency,
        information: {
          bankInformation: _vm.bankInformation,
          id: _vm.$route.params.id,
        },
      },
    }}},[_vm._v("Edit")]),_c('br'),_c('br'),_c('span',{staticClass:"link-span"},[_vm._v("Your Link:")]),_c('span',{staticClass:"link-span"},[_vm._v(_vm._s(_vm.link))]),_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.link),expression:"link",arg:"copy"}],staticClass:"copy-button",attrs:{"type":"button"}},[_vm._v(" copy ")]),_c('br'),_c('br'),_c('span',[_vm._v(" "+_vm._s("Used: " + this.travel.length + " times"))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }